










































import {Component, Prop, Vue} from 'vue-property-decorator';
import {CompilationService, TrackService} from '@/api';
import {Compilation, TrackListItem} from '@/types';
import UPagination from '@/ui-lib/pagination/Pagination.vue';
import UBlock from '@/ui-lib/block/Block.vue';
import {Route} from 'vue-router';
import TrackTableList from '@/components/track/TrackTableList.vue';

const limit = 30;

const getStart = (route: Route) => typeof route.query.start === 'string' ? parseInt(route.query.start, 10) : 0;

@Component<CompilationTracksView>({
  async beforeRouteEnter(to, from, next) {
    try {
      const id = parseInt(to.params.id, 10);

      const {list, total} = await TrackService.list({
        start: getStart(to),
        limit: limit,
        compilationsIds: [id],
      });

      next((vm) => {
        vm.tracks = list;
        vm.total = total;
      });
    } catch (e) {
      next(e);
    }
  },

  async beforeRouteUpdate(to, from, next) {
    try {
      await this.updateList(to);

      next();
    } catch (e) {
      next(e);
    }
  },

  metaInfo() {
    return {
      title: 'Треки',
    };
  },

  components: {
    UPagination,
    UBlock,
    TrackTableList,
  },
})
export default class CompilationTracksView extends Vue {
  @Prop({required: true})
  private compilation!: Compilation;

  private total = 0;
  private tracks: TrackListItem[] = [];
  private isLoading = false;

  private get limit() {
    return limit;
  }

  private get start() {
    return getStart(this.$route);
  }

  private changeStart(newStart: number) {
    this.$router.push({
      query: {
        ...this.$route.query,
        start: newStart.toString(),
      },
    });
  }

  private async updateList(route: Route) {
    const id = parseInt(route.params.id, 10);

    const {list, total} = await TrackService.list({
      start: getStart(route),
      limit: limit,
      compilationsIds: [id],
    });

    this.tracks = list;
    this.total = total;
  }

  private async detach(track: TrackListItem) {
    if (!confirm(`Удалить трек «‎${track.title}»‎ из подборки «‎${this.compilation.name}»‎?`)) {
      return;
    }

    this.isLoading = true;

    try {
      await CompilationService.trackDetach(this.compilation.id, track.id);
      await this.updateList(this.$route);

      this.$emit('tracks-count-update', this.total);
    } catch (e) {
      alert(e);
    }

    this.isLoading = false;
  }
}
